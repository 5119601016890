@import "@fontsource/marcellus";
@import "@fontsource/marcellus-sc";
.info-us {
  font-family: "Marcellus SC";
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 5em;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  &-content {
    max-width: 1200px;
    min-height: 50vh;

    .our-story {
      z-index: 1;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 32px;
      position: relative;
      .title {
        max-width: 200px;
      }
      .content {
        max-width: 700px;
        text-align: left;
      }
    }
    .about-us {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 5%;
      position: relative;

      .content {
        max-width: 400px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .profile-pic-polaroid {
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          background-color: rgb(247, 246, 246);
          width: 280px;
          padding: 10px 0 40px 0;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          .profile-pic {
            width: 250px;
            height: 300px;
          }
        }
      }
    }
  }
  .wedding-illustration {
    position: absolute;
    left: 0;
    bottom: 20%;

    @media (max-width: 1300px) {
      width: 300px;
    }
    @media (max-width: 1080px) {
      width: 250px;
      bottom: 0;
    }
  }
  .fingerprint-illustration {
    position: absolute;
    transform: rotate(0.1turn);
    top: 0;
    left: -5%;
    z-index: 0;
    width: 300px;

    @media (max-width: 1300px) {
      width: 300px;
    }
    @media (max-width: 1080px) {
      width: 250px;
      bottom: 0;
    }
  }
  .blob-illustration {
    position: absolute;
    transform: rotate(0.1turn);
    bottom: -10%;
    right: -5%;
    z-index: 0;

    @media (max-width: 1300px) {
      width: 400px;
    }
  }
}
