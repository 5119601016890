.navbar {
  background-color: #ffffff;
  padding: 1em;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 10;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    padding: 8px;
    color: #000000;
  }
}
