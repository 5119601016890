.menu-section {
  font-family: "Marcellus";
  padding: 32px;
  background-color: #3e3e3e;
  color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;

  &-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  &-header {
    font-size: 18px;
    font-weight: bold;
  }
  &-info {
    max-width: 350px;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }
}
