@import "@fontsource/marcellus";
@import "@fontsource/marcellus-sc";

.intro {
  height: 100vh;
  background-image: url("../photos/photo_2.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  // filter: grayscale(100%);
  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter: gray;
  // -webkit-filter: grayscale(1);

  .title {
    font-family: "Marcellus SC";
    font-size: 40;
    // margin-top: 5%;
    margin-bottom: 2%;
    align-self: center;
    text-align: center;
  }
  .box {
    background-color: rgb(30, 30, 30, 0.4);
    padding: 10%;
    margin: 5%;
    border-radius: 15px;
    color: white;
  }
  .subtitle {
    text-align: center;
    font-family: "Marcellus SC";
    margin-bottom: 2%;
  }

  .photoGrid {
    margin-top: 10%;
    .gridItem {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      max-width: 100%;
    }
    .smallItem {
      img {
        max-width: 60%;
      }
    }
  }
}
